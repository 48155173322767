import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
} from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors } from '@angular/common/http';
import { AbstractSecurityStorage, AuthModule } from 'angular-auth-oidc-client';
import { authnConfig } from './authn.config';
import { loadingScreenConfig } from '@config/loading-screen.config';
import { provideRouterWithConfig } from '@config/router.config';
import { authBundleConfig } from '@config/auth-bundle.config';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  apiConfigurationProvider,
  requestBundleConfig,
} from '@config/request-bundle.config';
import { storageBundleConfig } from '@config/storage-bundle.config';
import { TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from '@core/title-strategies/page-title-strategy';
import { CookiebotConfig } from '@config/cookiebot.config';
import { CookieConsentService } from '@core/services/cookie-consent.service';
import { cookieConsentServiceFactory } from '@core/factories/cookie-consent-service.factory';
import { piwikProConfig } from '@config/piwik-pro.config';
import { initializeInMemoryStoreFactory } from '@config/in-memory-store-initializer.factory';
import {
  ffNgxLoadingScreenProvider,
  ffNgxAuthBundleProvider,
  FfNgxAuthOidcCustomStore,
  ffNgxRequestsBundleProvider,
  ffNgxStorageBundleProvider,
  FfNgxStorageService,
  ffNgxPiwikProProvider,
  ffNgxFormErrorProvider,
  FfNgxDateParserFormatter,
  NgbDateParserFormatter,
} from '@fagforbundet/ngx-components';
import { controlErrorMessages } from '@config/control-errors/control-error.config';
import { MockInterceptor } from 'src/app/mock.interceptor';
import { ngxCookiebotProvider } from '@halloverden/ngx-cookiebot';

export const appConfig: ApplicationConfig = {
  providers: [
    apiConfigurationProvider(),
    ffNgxAuthBundleProvider(authnConfig, authBundleConfig),
    ffNgxFormErrorProvider(controlErrorMessages),
    ffNgxLoadingScreenProvider(loadingScreenConfig),
    ffNgxPiwikProProvider(piwikProConfig),
    ffNgxRequestsBundleProvider(requestBundleConfig),
    ffNgxStorageBundleProvider(storageBundleConfig),
    importProvidersFrom(AuthModule),
    ngxCookiebotProvider(CookiebotConfig),
    provideAnimations(),
    provideHttpClient(withInterceptors([])),
    provideRouterWithConfig(),
    {
      provide: APP_INITIALIZER,
      useFactory: cookieConsentServiceFactory,
      deps: [CookieConsentService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeInMemoryStoreFactory,
      deps: [FfNgxStorageService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: MockInterceptor, multi: true },
    {
      provide: AbstractSecurityStorage,
      useClass: FfNgxAuthOidcCustomStore,
    },
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: FfNgxDateParserFormatter,
    },
  ],
};
